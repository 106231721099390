/* eslint-disable no-undef */
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://api.localy.app',
  headers: {
    'Content-Type': 'application/json'
  }
});

export const addLead = async (email) => {
  await axiosInstance.post('/add-lead', { email });
};
