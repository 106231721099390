/* eslint-disable react/prop-types */

export const FilledButton = ({ text, handleClick }) => {
  return (
    <button
      className="bg-white py-3 md:px-6 px-4 md:text-base text-xs text-pink font-europe-extra-bold mt-3 hover:bg-pink hover:text-white transition-colors ease-out duration-300"
      onClick={handleClick}
      style={{ borderRadius: '6px' }}>
      {text}
    </button>
  );
};

export const OutlineButton = ({ text, handleClick }) => {
  return (
    <button
      className="border-2 border-pink py-3 md:px-6 px-4 md:text-base text-xs text-pink font-europe-extra-bold hover:bg-pink hover:text-white transition-colors ease-out duration-300"
      onClick={handleClick}
      style={{ borderRadius: '6px' }}>
      {text}
    </button>
  );
};
