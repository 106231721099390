import { useState } from 'react';
import SVG from 'react-inlinesvg';
import { addLead } from '../api';
import { FilledButton } from '../components/Button';

const Footer = () => {
  const [email, setEmail] = useState();

  return (
    <div
      id="contact-us"
      className="relative w-full h-full md:py-20 pt-10 pb-8 early-access-gradient-bg overflow-hidden">
      <div className="md:w-4/5 w-11/12 m-auto">
        <div className="w-[200px] h-[95px] flex">
          <SVG
            src="icons/speech-bubble.svg"
            className="w-[200px] h-[95px] absolute z-0"
            title="twitter-icon"
          />
          <p className="font-semibold text-white text-sm align-items my-auto ml-4 pb-2">
            Let’s be friends :-)
          </p>
        </div>

        <div className="xl:w-4/5 w-11/12">
          <h1 className="md:text-5xl text-3xl font-europe-extra-bold text-white">
            <span className="text-black">GET EARLY ACCESS</span> TO EXCLUSIVE{' '}
            <br className="xl:block hidden" />
            ATTRACTIONS, EVENTS, AND
            <br className="xl:block hidden" /> OFFERS
          </h1>
        </div>

        <div className="relative flex md:flex-row flex-col w-full md:gap-0 gap-y-8 lg:m-0 mt-5">
          <div className="md:mx-auto lg:pl-20 pl-0 m-0 xl:w-1/3 md:w-1/2 w-11/12 xl:-mt-5 mt-3 z-10">
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="bg-transparent p-2 placeholder-light-blue w-[90%] text-white"
              style={{ border: '1px solid #bae5f9', borderRadius: '6px' }}
              placeholder="Your Email"
            />
            <br />
            <FilledButton
              text="JOIN THE WAITLIST"
              handleClick={async () => {
                if (email && email !== '') {
                  await addLead(email);
                  setEmail('');
                }
              }}
            />
          </div>
          <div className="xl:w-5/6 md:w-full w-screen absolute xl:-mt-20 md:-mt-10 mt-20 md:pt-0 pt-10 z-0">
            <img
              className="ml-auto m-3 md:w-72 w-60"
              // eslint-disable-next-line no-undef
              src={require('../assets/iphone-mockup.png')}
            />
          </div>
        </div>

        <div className="relative flex lg:flex-row flex-col lg:gap-y-0 gap-y-8 text-white text-sm justify-between mx-auto pt-40">
          <p className="md:order-first order-last font-light">© Localy 2022. All rights reserved</p>

          <div className="flex md:flex-row flex-col md:gap-x-8 gap-y-4 ">
            <a
              href="https://twitter.com/Localyofficial"
              target="_blank"
              className="flex gap-x-1 group hover:cursor-pointer"
              rel="noreferrer">
              <SVG
                src="icons/twitter-white.svg"
                width={20}
                height={20}
                title="twitter-white"
                className="fill-white group-hover:fill-pink group-active:fill-blue"
              />
              <p className="font-roboto-bold ml-1 group-hover:text-pink group-active:text-blue">
                Twitter
              </p>
            </a>
            <a
              href="https://www.instagram.com/localyofficial/"
              target="_blank"
              className="flex gap-x-1 group hover:cursor-pointer"
              rel="noreferrer">
              <SVG
                src="icons/instagram-white.svg"
                width={20}
                height={20}
                title="instagram-white"
                className="fill-white group-hover:fill-pink group-active:fill-blue"
              />
              <p className="font-roboto-bold ml-1  group-hover:text-pink group-active:text-blue">
                Instagram
              </p>
            </a>
          </div>

          <div>
            <p className="font-robot opacity-50">To learn more:</p>
            <div className="flex gap-x-1">
              <SVG src="icons/mail-icon.svg" width={20} height={20} title="mail-icon" />
              <a
                href="mailto:hello@localy.app"
                className="font-roboto ml-1 text-base font-medium decoration-white/[.3] hover:decoration-white/[3] underline underline-offset-4 hover:cursor-pointer">
                hello@localy.app
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
