/* eslint-disable no-undef */
import React, { useCallback, useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';

const NUMBER_OF_IMAGES = 9;
const imageDescriptions = [
  'Private tasting menu at Michelin star restaurants.',
  'Adventurous excursions off the beaten path.',
  '“Impossible to get” backstage concert passes.'
];

const UnshadedPhoneMockup = ({ index, currentIndex }) => {
  const willAnimate = currentIndex === index;
  const isPrev = index < currentIndex;
  return (
    <div
      className={`relative ${isPrev ? 'invisible' : ''}`}
      style={{
        transform: willAnimate ? 'scaleX(1.20) scaleY(1.58) translateX(-300px)' : '',
        opacity: willAnimate ? 0 : 1,
        transition: 'transform, opacity 0.3s'
      }}>
      <img
        src={require('../assets/possibilities-page/unshaded-phone.png')}
        className="h-[450px] w-[310px]"
        alt="Unshaded Phone Mockup"
      />
      <img
        src={require(`../assets/possibilities-page/carousel-image-${index}.png`)}
        className="absolute top-[20px] left-[20px] h-[430px] w-[270px]"
        alt="Screen Mockup"
      />
      <img
        src={require('../assets/possibilities-page/nft-logo-circle.png')}
        className="absolute top-[30px] left-[30px] h-[60px] w-[60px]"
        alt="Localy Logo"
      />
    </div>
  );
};

function PossibilitiesPage() {
  const [index, setIndex] = useState(1);
  const [opacity, setOpacity] = useState(false);

  const goNext = () => {
    if (index < NUMBER_OF_IMAGES) {
      setIndex(index + 1);
    }
  };

  const goPrev = () => {
    if (index > 1) {
      setIndex(index - 1);
    }
  };

  const getNextImages = () => {
    let nextImages = [];
    for (let i = 2; i <= NUMBER_OF_IMAGES; i++) {
      nextImages.push(<UnshadedPhoneMockup index={i} currentIndex={index} />);
    }

    return nextImages;
  };

  const getTranslateXValue = () => {
    const value = (index - 1) * (310 + 112);

    return `translateX(-${value}px)`;
  };

  const handleActionClick = useCallback((action) => {
    setOpacity(false);
    action && action();
    setTimeout(() => {
      setOpacity(true);
    }, 200);
  }, []);

  useEffect(() => {
    handleActionClick();
  }, []);

  return (
    <div id="possibilities" className="relative sm:h-[970px] h-[550px] overflow-hidden">
      <div className="absolute 2xl:top-48 2xl:left-44 sm:left-12 sm:top-20 top-16 md:mx-0 mx-4">
        <h1 className="font-europe-extra-bold text-black md:text-5xl text-3xl">UNLOCK</h1>
        <h1 className="text-outline-blue font-europe-extra-bold text-blue mt-2 md:text-[5rem] text-[2.7rem] leading-none">
          A WORLD OF POSSIBILITIES
        </h1>
      </div>
      <div className="absolute bottom-0 2xl:left-44 lg:left-5 4 xl:left-5 transition-opacity duration-700 ease-linear lg:block hidden">
        <div className="relative 2xl:pl-[90px] 2xl:pr-[90px] pl-[50px] pr-[50px]">
          {imageDescriptions[index - 1] && (
            <p
              className={`font-roboto-bold text-4xl absolute w-1/2 -right-56 top-10 ${
                opacity ? 'show' : 'hide'
              }`}>
              {imageDescriptions[index - 1]}
            </p>
          )}
          <div className={`${opacity ? 'show' : 'hide'} relative`}>
            <img
              src={require('../assets/possibilities-page/shaded-phone.png')}
              className="h-[580px] w-[370px]"
              alt="Shaded Phone Mockup"
            />
            <img
              src={require(`../assets/possibilities-page/carousel-image-${index}.png`)}
              className="absolute top-[20px] left-[20px] h-[560px] w-[330px]"
              alt="Screen Mockup"
            />
            <img
              src={require('../assets/possibilities-page/nft-logo-circle.png')}
              className="absolute top-[30px] left-[35px] h-[70px] w-[70px]"
              alt="Localy Logo"
            />
          </div>
          <button
            className="carousel-nav-btn absolute p-3 rounded-full border border-pink border-solid hover:bg-pink transition-colors duration-300 top-2/4 left-0 z-30"
            onClick={() => handleActionClick(goPrev)}>
            <SVG src="chevron-left.svg" width={15} height={15} stroke="#A96CC6" title="Previous" />
          </button>
          <button
            className="carousel-nav-btn absolute p-3 rounded-full border border-pink border-solid hover:bg-pink transition-colors duration-300 top-2/4 right-0 z-30"
            onClick={() => handleActionClick(goNext)}>
            <SVG src="chevron-right.svg" width={15} height={15} stroke="#A96CC6" title="Next" />
          </button>
        </div>
      </div>
      <div className="bottom-0 -left-12 absolute flex lg:hidden">
        <div className="transition-opacity duration-700 ease-linear lg:hidden block">
          <div className={`${opacity ? 'show' : 'hide'} relative sm:w-[350px] w-[230px]`}>
            <img
              src={require('../assets/possibilities-page/shaded-phone.png')}
              className="sm:h-[550px] sm:w-[350px] h-[330px] w-[230px]"
              alt="Shaded Phone Mockup"
            />
            <img
              src={require(`../assets/possibilities-page/carousel-image-${index}.png`)}
              className="absolute top-[15px] left-[15px] sm:h-[535px] sm:w-[320px] h-[315px] w-[200px]"
              alt="Screen Mockup"
            />
            <img
              src={require('../assets/possibilities-page/nft-logo-circle.png')}
              className="absolute top-[25px] right-[25px] sm:h-[60px] sm:w-[60px] h-[40px] w-[40px]"
              alt="Localy Logo"
            />
          </div>
        </div>
        <div className="pl-5 relative">
          {imageDescriptions[index - 1] && (
            <p
              className={`font-roboto-bold sm:text-4xl text-2xl sm:w-[80%] w-[95%] sm:mt-20 mt-10 ${
                opacity ? 'show' : 'hide'
              }`}>
              {imageDescriptions[index - 1]}
            </p>
          )}
          <div className="absolute sm:bottom-20 bottom-14 flex gap-x-5">
            <button
              className="carousel-nav-btn p-3 rounded-full border border-pink border-solid hover:bg-pink transition-colors duration-300 z-30"
              onClick={() => handleActionClick(goPrev)}>
              <SVG
                src="chevron-left.svg"
                className="w-[20px] h-[20px] sm-[30px] sm-[30px]"
                stroke="#A96CC6"
                title="Previous"
              />
            </button>
            <button
              className="carousel-nav-btn p-3 rounded-full border border-pink border-solid hover:bg-pink transition-colors duration-300 z-30"
              onClick={() => handleActionClick(goNext)}>
              <SVG
                src="chevron-right.svg"
                className="w-[20px] h-[20px] sm-[30px] sm-[30px]"
                stroke="#A96CC6"
                title="Next"
              />
            </button>
          </div>
        </div>
      </div>
      <div className="hidden absolute bottom-0 w-[3264px] h-[580px] lg:flex items-end image-carousel">
        <div
          className="flex gap-x-28"
          style={{ transform: getTranslateXValue(), transition: 'transform 0.5s' }}>
          {getNextImages()}
        </div>
      </div>
    </div>
  );
}

export default PossibilitiesPage;
