/* eslint-disable no-undef */
import { Carousel } from '../components/Carousel';
import SVG from 'react-inlinesvg';

const TeamMember = ({ icon, title, name, description, logo }) => {
  return (
    <div className="flex flex-row xl:w-1/2 md:w-3/4 w-full mx-auto gap-x-2">
      <img className="md:w-28 md:h-28 w-1/4 h-1/4" src={require(`../assets/team-page/${icon}`)} />
      <div className="flex flex-col gap-y-2 mx-2 w-3/4 h-3/4">
        <div className="flex -ml-2">
          <div className="w-0 h-0 border-t-transparent border-r-8 border-r-blue border-b-8 border-b-transparent"></div>
          <div className="bg-blue w-fit rounded-r-md rounded-bl-md">
            <p className="font-semibold text-white m-1 text-sm">{title}</p>
          </div>
        </div>
        {name.toUpperCase() === 'PAWEŁ ŁASKARZEWSKI' ? (
          <p className="font-europe-extra-bold text-base">
            PAWE<span className="font-black text-[1.05rem]">Ł</span>{' '}
            <span className="font-black text-[1.05rem]">Ł</span>
            ASKARZEWSKI
          </p>
        ) : (
          <p className="font-europe-extra-bold text-base">{name.toUpperCase()}</p>
        )}
        <p className="lg:w-4/5 w-full text-sm font-light">{description}</p>
        {logo ? <img className="w-1/4" src={require(`../assets/team-page/${logo}`)} /> : null}
      </div>
    </div>
  );
};

const Advisor = ({ icon, name, description }) => {
  return (
    <div className="flex flex-row xl:w-[25vw] lg:w-[27vw] sm:w-[80vw] w-screen h-44 gap-x-2 group items-start">
      <div className="flex flex-col gap-y-2 items-center w-fit">
        <SVG
          src="border-semi-circle.svg"
          title="border-semi-circle"
          className="transform transition duration-500 group-hover:rotate-90 lg:w-32 lg:h-32 md:w-28 md:h-28 w-32 h-32 absolute z-0"
        />
        <div className="lg:w-32 lg:h-32 md:w-28 md:h-28 w-32 h-32 p-2 rounded-full relative flex justify-center items-center z-0">
          <img className="w-full h-full" src={require(`../assets/team-page/${icon}`)} />
        </div>

        <div className="w-full z-10 -mt-6 text-center">
          <p className="font-europe-extra-bold text-md">{name.toUpperCase()}</p>
        </div>
      </div>
      <p className="xl:w-full sm:w-[50vw] w-full text-sm font-light mx-1 break-words z-10">
        {description}
      </p>
    </div>
  );
};

const teamA = [
  {
    icon: 'alex-danielyan-icon.png',
    title: 'CEO Co-Founder',
    name: 'Alex Danielyan',
    description:
      'Serial entrepreneur, crypto-consultant, successful exit for a location-based dating app.',
    logo: 'jean-moulin-logo.png'
  },
  {
    icon: 'simon-bogdanowicz-icon.png',
    title: 'CMO Co-Founder',
    name: 'Simon Bogdanowicz',
    description:
      'Serial entrepreneur, crypto leader since 2017, career in Silicon Valley and Wall Street',
    logo: 'catalyst-global-logo.png'
  },
  {
    icon: 'paweł-łaskarzewski-icon.png',
    title: 'CSO Co-Founder',
    name: 'Paweł Łaskarzewski',
    description:
      'Over 20 years of experience in tech, co-founded 6 businesses with 3 successful exits. Recognized as a 2022 Blockchain Expert by USA Today'
  }
];

const teamB = [
  {
    icon: 'viktoriia-miracle-icon.png',
    title: 'CBDO Co-Founder',
    name: 'Viktoriia Miracle',
    description:
      'Serial entrepreneur, 12+ years in businesses development. Expert in Neuromarketing, Leadership, and Sales'
  },
  {
    icon: 'arjan-bhardwaj-icon.png',
    title: 'CTO Co-Founder',
    name: 'Arjan Bhardwaj',
    description:
      'Nearly 3 decades in tech focused on merchant banking sector, CEO of FINTRAC-regulated business',
    logo: 'lemont-capital-logo.png'
  },
  {
    icon: 'stuart-kluz-burton-icon.png',
    title: 'Business Growth Strategist',
    name: 'Stuart Kluz Burton',
    description: 'Social media expert with 14+ years experience in marketing and business growth.'
  }
];

const advisors = [
  {
    icon: 'david-ellington-icon.png',
    name: 'David Ellington',
    description:
      'Former Chairman @$20 Billion San Francisco Employee Retirement system. Former President @Gridspeak; Former CEO @NetNoir'
  },
  {
    icon: 'fred-song-icon.png',
    name: 'Fred Song',
    description:
      '20yr film & video game producer Produced films for Lionsgate PTE crypto gaming specialist'
  },
  {
    icon: 'brandon-zatt-icon.png',
    name: 'Brandon Zatt',
    description:
      'Over 20 years experience on the ground in Asia, we connect leading blockchain startups with markets, investors and the best bleeding edge tech'
  },
  {
    icon: 'sol-pal-john-bejarano-icon.png',
    name: 'Sol-Pal John Bejarano',
    description:
      'CEO and Head of Strategy for First Class Alliance, where he leads a team focused on business growth and profitability. He’s an expert in brand strategy, digital and NFT marketing'
  },
  {
    icon: 'vamsi-kumar-kotla-icon.png',
    name: 'Vamsi Kumar Kotla',
    description:
      'AI, Crypto, NFT, & Blockchain Extraordinaire. innovative serial entrepreneur. CEO of Rush49'
  },
  {
    icon: 'susan-oh-icon.png',
    name: 'Susan Oh',
    description:
      'A Civic technologist in Al and blockchain, founder of Muckr.Al and Blockchain Commission for Sustainable Development. recipient of the Quantum Impact Award'
  }
];

const getAdvisors = () => {
  return advisors.map((advisor, index) => (
    <Advisor
      key={index}
      icon={advisor.icon}
      name={advisor.name}
      description={advisor.description}
    />
  ));
};

const TeamPage = () => {
  return (
    <div
      id="team"
      className="relative w-full h-full team-page-gradient-bg justify-center py-20 sm:block hidden">
      <div className="h-fit m-auto w-[90%] pb-10">
        <h1 className="font-europe-extra-bold md:text-5xl text-3xl text-white lg:text-center text-justify">
          MEET THE TEAM
        </h1>
      </div>
      <div className="flex flex-col pb-10 xl:w-[90%] w-[95%] mx-auto">
        <div className="flex lg:flex-row flex-col xl:w-4/5 w-full gap-y-10 mx-auto mb-12">
          {teamA.map((member, index) => (
            <TeamMember
              icon={member.icon}
              title={member.title}
              name={member.name}
              description={member.description}
              logo={member.logo}
              key={index}
            />
          ))}
        </div>

        <div className="flex lg:flex-row flex-col xl:w-4/5 w-full gap-y-10 mx-auto mb-12">
          {teamB.map((member, index) => (
            <TeamMember
              icon={member.icon}
              title={member.title}
              name={member.name}
              description={member.description}
              logo={member.logo}
              key={index}
            />
          ))}
        </div>
      </div>
      {/* <div className="relative h-full md:w-4/5 w-11/12 m-auto flex items-center py-12">
        <div className="flex-grow h-px bg-blue"></div>
        <span className="font-europe flex-shrink md:text-3xl text-2xl text-blue px-4">
          OUR ADVISORS
        </span>
        <div className="flex-grow h-px bg-blue"></div>
      </div>

      <Carousel items={getAdvisors()} /> */}
    </div>
  );
};

export default TeamPage;
