/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { addLead } from '../api';
import { FilledButton } from '../components/Button';

const menuItems = [
  { name: 'home', path: '#home' },
  { name: 'about', path: '#about' },
  { name: 'opportunities', path: '#opportunities' },
  { name: 'roadmap', path: '#roadmap' },
  { name: 'team', path: '#team' },
  { name: 'contact us', path: '#contact-us' }
];

const SidebarMenu = ({ isOpen, handleClose }) => {
  return (
    <>
      <div
        className={`w-full h-full z-30 bg-gray-200 bg-opacity-40 blur-background absolute top-0 ${
          isOpen ? '' : 'hidden'
        }`}
      />
      <div
        className={`sm:w-[40%] w-2/3 h-[100vh] bg-blue z-50 fixed top-0 right-0 p-5 ${
          isOpen ? '' : 'hidden'
        }`}>
        <div className="flex justify-between items-center">
          <div className="flex gap-x-5">
            <SVG
              className="fill-white"
              src="icons/twitter-white.svg"
              width={30}
              height={30}
              title="twitter-icon"
            />
            <SVG
              className="fill-white"
              src="icons/instagram-white.svg"
              width={30}
              height={30}
              title="instagram-icon"
            />
          </div>
          <div className="rounded-[8px] p-4 bg-white bg-opacity-20 inline-block">
            <SVG
              src="icons/close-icon.svg"
              width={17}
              height={17}
              title="close-icon"
              onClick={handleClose}
            />
          </div>
        </div>
        <div className="flex flex-col mt-8">
          {menuItems.map((item, index) => {
            return (
              <div
                className={`text-white font-europe-extra-bold text-lg text-center py-4 border-t border-white border-opacity-20 ${
                  index === menuItems.length - 1 ? 'border-b' : ''
                }`}
                key={index}>
                <a href={item.path} onClick={handleClose}>
                  {item.name.toUpperCase()}
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

const CTACard = () => {
  const [hasJoinedWaitlist, setHasJoinedWaitlist] = useState(false);
  const [email, setEmail] = useState();

  return (
    <div className="sm:absolute sm:bottom-0 sm:right-0 relative">
      <div className="relative">
        <div className="absolute md:-top-24 -top-16 sm:w-[550px] lg:w-[600px] w-full hidden sm:block">
          <div className="inline-block md:float-right float-left relative">
            <SVG src="icons/blue-callout-icon.svg" title="blue-callout-icon" />
            <p className="text-white font-roboto-bold top-[5px] left-[20px] absolute text-lg">
              We won&apos;t spam you!
            </p>
          </div>
        </div>
        <div className="cta-card-gradient-bg text-white md:p-8 p-5 lg:h-[300px] sm:w-[550px] lg:w-[600px] w-full h-auto rounded-[10px]">
          <div className="flex items-center h-full">
            <>
              {hasJoinedWaitlist ? (
                <div className="md:w-3/5 w-[70%] md:pt-0 py-6">
                  <h1 className="font-europe-extra-bold sm:text-2xl lg:text-3xl text-lg">
                    Thanks for signing up!
                  </h1>
                  <p className="font-roboto mt-4 mb-3">Follow us on social:</p>
                  <div className="flex gap-x-3">
                    <a
                      href="https://twitter.com/Localyofficial"
                      target="_blank"
                      className="bg-white py-2 md:px-6 px-4 text-black flex justify-center items-center rounded-[6px]"
                      rel="noreferrer">
                      <SVG
                        src="icons/twitter-icon.svg"
                        width={20}
                        height={20}
                        title="twitter-icon"
                      />
                      <span className="font-roboto-bold ml-1 md:text-base text-sm">Twitter</span>
                    </a>

                    <a
                      href="https://www.instagram.com/localyofficial/"
                      target="_blank"
                      className="bg-white py-2 md:px-6 px-4 text-black flex justify-center items-center rounded-[6px]"
                      rel="noreferrer">
                      <SVG
                        src="icons/instagram-icon.svg"
                        width={20}
                        height={20}
                        title="instagram-icon"
                      />
                      <span className="font-roboto-bold ml-1 md:text-base text-sm">Instagram</span>
                    </a>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col">
                  <div className="md:w-3/5 w-[70%]">
                    <h1 className="font-europe-extra-bold sm:text-2xl lg:text-3xl text-lg">
                      Get early access!
                    </h1>
                    <p className="font-roboto mb-5 md:text-base text-sm">
                      Exclusive attractions events and special offers only available through Localy
                    </p>
                  </div>
                  <div className="md:w-3/5 w-[100%]">
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="bg-transparent p-2 placeholder-light-blue w-full"
                      style={{ border: '1px solid #bae5f9', borderRadius: '6px' }}
                      placeholder="Your Email"
                    />
                    <br />
                    <FilledButton
                      text="JOIN THE WAITLIST"
                      handleClick={async () => {
                        if (email && email !== '') {
                          await addLead(email);
                          setHasJoinedWaitlist(true);
                          setEmail('');
                        }
                      }}
                    />
                  </div>
                </div>
              )}
            </>
            <img
              className="absolute md:-top-12 md:right-0 -top-28 -right-20 md:scale-95 scale-[0.45]"
              src={require('../assets/iphone-mockup.png')}
              alt="Image of an Iphone Mockup"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

function HeroPage() {
  const [sideMenuVisible, toggleSideMenu] = useState(false);
  const [activePath, setActivePath] = useState('#home');

  useEffect(() => {
    if (sideMenuVisible) {
      document.getElementById('root').style.overflow = 'hidden';
      document.getElementById('root').style.height = '100vh';
    } else {
      document.getElementById('root').style.overflow = 'auto';
      document.getElementById('root').style.height = 'auto';
    }
  }, [sideMenuVisible]);

  return (
    <>
      <SidebarMenu isOpen={sideMenuVisible} handleClose={() => toggleSideMenu(false)} />
      <div
        className="w-full lg:h-screen h-auto animate-hero-image relative overflow-hidden bg-center bg-cover"
        style={{ boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.4)' }}>
        <div className="flex flex-row justify-between items-center md:mx-10 mx-2 pt-2 md:pt-10">
          <img
            className="sm:block hidden"
            src={require('../assets/hero-page/localy-logo-blue-white.png')}
            alt="Localy Logo"
          />
          <img
            className="scale-90 ml-0 sm:hidden block"
            src={require('../assets/hero-page/localy-logo-all-white.png')}
            alt="Localy Logo"
          />
          <SVG
            className="lg:hidden block"
            src="icons/burger-menu-icon.svg"
            onClick={() => toggleSideMenu(true)}
            width={50}
            height={50}
            title="burger-menu-icon"
          />
          <div className="hidden lg:flex flex-row justify-center items-center gap-x-8">
            {menuItems.map(({ name, path }, index) => (
              <a
                href={path}
                key={index}
                onClick={() => setActivePath(path)}
                className={`font-europe-extra-bold text-white hover:cursor-pointer hover:text-opacity-100 ${
                  activePath === path ? 'text-opacity-100' : 'text-opacity-50'
                }`}>
                {name.toUpperCase()}
              </a>
            ))}
          </div>
        </div>
        <div className="2xl:mt-44 xl:mt-36 md:mt-30 mt-20 2xl:mx-52 xl:mx-24 sm:mx-12 mx-4 lg:h-[500px] sm:h-[550px] h-[90%] sm:block flex flex-col justify-center relative z-10">
          <div className="relative inline-block md:mb-0 mb-10">
            <h2 className="font-europe sm:text-2xl lg:text-3xl xl:text-5xl text-base text-blue">
              THE WORLD&apos;S FIRST
            </h2>
            <h1 className="font-europe-extra-bold xl:text-7xl md:text-5xl sm:text-4xl text-[1.75rem] leading-[2rem] text-white mt-2">
              LOCATION-BASED NFT <br /> MARKETPLACE
            </h1>
            <p className="text-white font-roboto mt-2 md:text-xl text-md">
              <span className="font-roboto-bold">Search and Unlock</span> Exclusive Experiences{' '}
              <br /> and Offers in Your City
            </p>
          </div>
          <CTACard />
        </div>
        <div className="hero-image-bottom-gradient absolute bottom-0 w-full h-2/5" />
      </div>
    </>
  );
}

export default HeroPage;
