/* eslint-disable no-undef */
import SVG from 'react-inlinesvg';

const RoadmapPage = () => {
  return (
    <div
      id="roadmap"
      className="relative w-full h-full md:pt-20 pt-10 pb-28 bg-blue overflow-x-auto overflow-y-hidden">
      <div className="absolute top-0 right-0">
        <img src={require('../assets/roadmap-page/running-android.png')} className="z-0" />
        <div className="absolute w-full h-full top-0 running-android-image-gradient z-0" />
      </div>

      <div className="w-[90%] mx-auto">
        <div className="absolute pt-10 font-europe-extra-bold md:text-5xl text-3xl text-white">
          <p>ROADMAP</p>
          <p>2022</p>
        </div>
        <div className="pt-[4rem] w-[1380px] mx-auto">
          <SVG
            className="absolute w-content"
            src="roadmap-direction-line.svg"
            title="roadmap-direction-line"
          />

          <div className="flex flex-row items-start justify-center gap-x-6 h-full z-10">
            <div className="flex mt-40 px-10 z-10">
              <ul className="flex flex-col gap-y-2 font-roboto text-left text-white check-list">
                <li>Created MVP and Beta test run on Android & iOS (Polygon)</li>
                <li>Whitepaper Written</li>
                <li>Soft-launched at NFT LA</li>
                <li>Market research and development completed initial partnerships established</li>
                <h1 className="font-europe-extra-bold md:text-5xl text-4xl text-black py-6">Q1</h1>
              </ul>
            </div>

            <div className="flex mt-48 px-10 z-10">
              <ul className="flex flex-col gap-y-2 font-roboto text-left text-white check-list">
                <li>Wireframes + Deep Dive Roadmap</li>
                <li>V2 Website Creation</li>
                <li>
                  Document/Template Essential Processes and Customer Journey Flows (improve u/x)
                </li>
                <li>Execute Grassroots Marketing Campaign</li>
                <li>Layout and Plan Community Growth Campaigns for Q4+</li>
                <li>Finalize Legalities</li>
                <h1 className="font-europe-extra-bold md:text-5xl text-4xl text-black py-6">Q2</h1>
              </ul>
            </div>

            <div className="flex mt-4 px-10 z-10">
              <ul className="flex flex-col gap-y-2 font-roboto text-left text-white list-disc">
                <li>Finalize City Launch Template</li>
                <li>Focus Group and Feedback</li>
                <li>Educational Library for B2B</li>
                <li>Start to Execute initial Community Growth Campaign</li>
                <li>Expand to Multichain - EVM Compatibility</li>
                <li>Expand Departments and Operations</li>
                <li>Launch 1 Beta Zones on Testnet</li>
                <li>Onboard Initial Creators and Blueprint Partner Platform Integrations</li>
                <h1 className="font-europe-extra-bold md:text-5xl text-4xl text-black py-6">Q3</h1>
              </ul>
            </div>

            <div className="flex mt-52 px-6 z-10">
              <ul className="flex flex-col gap-y-2 font-roboto text-left text-white list-disc">
                <li>Begin mainnet soft launch in Los Angeles, Las Vegas and Miami</li>
                <li>Start AR Integration with Gamification included</li>
                <li>Appoint Long-Term Regional Directors And Local Ambassadors</li>
                <li>Begin Marketplace Development</li>
                <li>Continue to Expand to Multichain” (NEAR Protocol, Algorand and Cudos)</li>
                <h1 className="font-europe-extra-bold md:text-5xl text-4xl text-black py-6">Q4</h1>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoadmapPage;
