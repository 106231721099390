/* eslint-disable no-undef */

function DiscoverPage() {
  return (
    <div id="discover" className="relative bg-white md:h-[950px] h-[550px] overflow-hidden">
      <img
        className="absolute bottom-0 z-10 md:h-auto h-[50%] w-full object-cover object-left"
        src={require('../assets/discover-page/buildings.png')}
        alt="Image of Buildings"
      />
      <div className="absolute bottom-0 md:right-0 -right-10">
        <div className="relative">
          <img src={require('../assets/discover-page/head.png')} alt="Image of a Head" />
          <div className="absolute top-0 head-image-gradient z-0 h-full w-full" />
        </div>
      </div>
      <div className="w-full absolute bottom-0 discover-page-gradient-bg z-20 md:h-[120px] h-[15px]" />
      <div className="w-full absolute bg-blue h-[500px] rounded-[50%] bg-opacity-40 blur-[600px] -bottom-[250px]" />
      <div className="2xl:mx-72 mx-4 md:pt-10 text-center z-20 relative">
        <div className="md:h-12 h-10 lg:mt-28 mt-10 overflow-hidden relative">
          <h1 className="absolute mx-0 my-auto left-0 right-0 top-0 font-europe-extra-bold md:text-5xl text-4xl text-black animate-text-scroll-up md:leading-[4rem] leading-[4rem]">
            EXPERIENCE. <br />
            UNLOCK. <br />
            COLLECT. <br />
            REDEEM. <br />
            SHARE.
          </h1>
        </div>
        <h1 className="font-europe-extra-bold md:text-5xl text-3xl mt-12 text-blue">
          DISCOVER THE WORLD UNLIKE EVER BEFORE WITH LOCALY
        </h1>
      </div>
    </div>
  );
}

export default DiscoverPage;
