/* eslint-disable no-undef */
import SVG from 'react-inlinesvg';

// for some reason, image doesn't get loaded when the whole url is passed as prop so i'm passing index as workaround instead
const MockUpWithDescription = ({ index, description }) => {
  return (
    <div
      className="flex flex-col items-center gap-y-2 z-10"
      style={{ width: '270px', height: '620px' }}>
      <img
        src={require(`../assets/opportunities-page/phone-mockup-${index}.png`)}
        alt="Image of an IPhone Mockup"
        style={{ height: '535px', width: '265px', borderRadius: '35px' }}
      />
      <SVG src="icons/blue-chevron-up.svg" width={15} height={15} title="blue-chevron-up" />
      <p className=" font-roboto text-center">{description}</p>
    </div>
  );
};

function OpportunitiesPage() {
  return (
    <div className="opportunities-page-gradient-bg sm:h-[1500px] h-[1300px] overflow-hidden">
      <div id="about" className="sm:h-[500px] h-[400px] relative">
        <div>
          <img
            className="xl:w-[580px] 2xl:w-[650px] absolute xl:top-0 xl:left-0 xl:scale-100 scale-x-[-1] lg:w-[700px] sm:w-[650px] w-[500px] bottom-0 right-0"
            src={require('../assets/opportunities-page/hand.png')}
            alt="Image of a Hand Holding a Ball as Symbolism of a Globe"
          />
        </div>
        <div className="md:w-[600px] sm:w-[500px] w-auto mx-4 2xl:mr-52 xl:mr-24 xl:float-right xl:mt-32 md:ml-12 md:pt-16 pt-16">
          <h1 className="md:text-5xl text-3xl font-europe-extra-bold text-white">
            <span className="text-black">THE WORLD</span> AT YOUR FINGERTIPS
          </h1>
          <p className="sm:mt-5 sm:text-xl mt-3 text-sm text-white">
            <span className="font-europe-extra-bold">Get rewarded</span> for exploring what your
            city <br /> offers while minting unique memories owned <br /> only by you.
          </p>
        </div>
      </div>
      <div id="opportunities" className="md:pt-24 pt-12 sm-[1000px] h-[900px] relative">
        <SVG
          className="absolute z-0 md:bottom-80"
          src="horizontal-dashed-scribble.svg"
          title="horizontal-dashed-scribble"
        />
        <div className="xl:px-0 px-4 md:px-12 flex items-start xl:justify-center relative overflow-x-scroll">
          <div className="relative w-content h-content flex items-start lg:gap-x-6 gap-x-5">
            <h1 className="absolute xl:-left-10 left-0 top-8 font-europe-extra-bold md:text-5xl text-3xl text-white">
              THE OPPORTUNITIES <br /> <span className="text-black">ARE ENDLESS</span>
            </h1>
            <div className="flex md:mt-36 mt-32 md:gap-x-6 gap-x-5">
              <MockUpWithDescription
                description="Explore Scheduled & Limited Quantity NFT Drops in Real-Time"
                index={1}
              />
              <MockUpWithDescription
                description="Mint the Location of Special Event to Unlock Time-based Offers"
                index={2}
              />
            </div>
            <div className="flex md:gap-x-6 gap-x-5">
              <MockUpWithDescription
                description="Unlock a Secret Menu at a Michelin-star Restaurant"
                index={3}
              />
              <MockUpWithDescription description="Start & Grow your NFT Portfolio" index={4} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OpportunitiesPage;
