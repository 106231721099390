/* eslint-disable no-undef */
import React, { useState } from 'react';
import SVG from 'react-inlinesvg';
import { OutlineButton } from '../components/Button';

const Step = ({ number, name, description, iconSrc, iconTitle }) => {
  return (
    <div
      id="how-it-works"
      className={`flex sm:flex-col sm:justify-start gap-y-8 justify-between sm:w-[300px] sm:h-auto ${
        name.toLowerCase() === 'join' ? 'h-[150px]' : 'h-[100px]'
      }`}>
      <div className="relative sm:w-[70px] sm:h-[70px] w-[50px] h-[50px]">
        <SVG
          className="relative z-10 sm:w-[70px] sm:h-[70px] w-[50px] h-[50px]"
          src={iconSrc}
          title={iconTitle}
        />
        <div className="absolute sm:block hidden bg-white z-0 h-[2px] w-[95px] bottom-0 -left-[25px]" />
        {number === 2 && (
          <div className="absolute sm:hidden block bg-white z-0 h-[65px] w-[2px] top-0 left-0" />
        )}
        <div className="absolute bg-blue text-white sm:text-base text-sm font-europe-extra-bold rounded-full z-10 sm:w-[24px] sm:h-[24px] sm:-left-[12px] sm:-bottom-[12px] w-[20px] h-[20px] -left-[10px] -bottom-[10px] flex justify-center">
          {number}
        </div>
        <div className="animate-ping absolute border-2 border-blue rounded-full sm:w-[24px] sm:h-[24px] sm:-left-[12px] sm:-bottom-[12px] w-[20px] h-[20px] -left-[10px] -bottom-[10px]" />
      </div>
      <div className="text-left sm:w-auto w-[268px]">
        <h1 className="font-europe-extra-bold sm:text-2xl text-lg">{name.toUpperCase()}</h1>
        <p className="font-roboto-thin sm:text-lg sm:mt-2 mt-0">{description}</p>
        {name.toLowerCase() === 'join' && (
          <div className="sm:mt-3 mt-1">
            <OutlineButton
              text="JOIN AS BUSINESS"
              handleClick={() =>
                window.open(
                  'https://forms.clickup.com/2223299/f/23v63-7727/WF31XDMIHHTSWDQN90',
                  '_blank'
                )
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

const Button = ({ isActive, text, handleClick }) => {
  if (isActive) {
    return (
      <div className="relative">
        <button
          onClick={handleClick}
          className="text-pink md:text-base text-xs font-europe-extra-bold bg-pink bg-opacity-20 md:w-[160px] md:h-[45px] w-[130px] h-[35px] rounded-[6px]">
          {text}
        </button>
        <SVG
          className="absolute -bottom-2 md:left-[70px] left-[55px]"
          src="icons/pink-chevron-down.svg"
          width={20}
          height={8}
          title="Pink Chevron Down"
        />
      </div>
    );
  }

  return (
    <button
      onClick={handleClick}
      className="text-pink md:text-base text-xs font-europe-extra-bold border border-solid border-pink border-opacity-20 hover:border-opacity-100 md:w-[160px] md:h-[45px] w-[130px] h-[35px] rounded-[6px]">
      {text}
    </button>
  );
};

const stepsForUsers = [
  {
    name: 'Install App',
    description: 'Download the app from Apple and Android app store. It’s free and always will be.',
    iconSrc: 'icons/download-icon.svg',
    iconTitle: 'download-icon'
  },
  {
    name: 'Explore',
    description: 'Explore your area in real-time for NFT-based offers and events.',
    iconSrc: 'icons/location-icon.svg',
    iconTitle: 'location-icon'
  },
  {
    name: 'Mint Memories',
    description:
      'Own a piece of your favorite business, venue, and brand and receive endless rewards.',
    iconSrc: 'icons/brain-icon.svg',
    iconTitle: 'brain-icon'
  }
];

const stepsForBusiness = [
  {
    name: 'Join',
    description:
      'Create a business account with Localy in 3 minutes and Become first Web 3.0 business in your area.',
    iconSrc: 'icons/contact-icon.svg',
    iconTitle: 'contact-icon'
  },
  {
    name: 'Create',
    description:
      'Create NFT offer for your customers. Create it from scratch or choose from fully customizable templates.',
    iconSrc: 'icons/create-icon.svg',
    iconTitle: 'create-icon'
  },
  {
    name: 'Sell',
    description:
      'Own a piece of your favorite business, venue, and brand and receive endless rewards.',
    iconSrc: 'icons/money-icon.svg',
    iconTitle: 'money-icon'
  }
];

function HowItWorksPage() {
  const [target, setTarget] = useState('users');
  const steps = target === 'users' ? stepsForUsers : stepsForBusiness;

  return (
    <div className="lg:pt-20 pt-10 sm:text-center bg-white md:h-[650px] h-[550px] xl:mx-0 md:mx-12 mx-4">
      <h1 className="font-europe-extra-bold md:text-5xl text-3xl">HOW IT WORKS?</h1>
      <div className="flex gap-x-2 sm:justify-center justify-end mt-4">
        <Button
          isActive={target === 'users'}
          text="FOR USERS"
          handleClick={() => setTarget('users')}
        />
        <Button
          isActive={target === 'business'}
          text="FOR BUSINESS"
          handleClick={() => setTarget('business')}
        />
      </div>
      <div className="relative mt-7 sm:mt-12">
        <div className="sm:block hidden absolute left-0 top-[68px] border-b-2 border-dashed border-dark-blue border-opacity-20 w-full" />
        <div className="sm:hidden block absolute left-0 top-[64px] border-l-2 border-dashed border-dark-blue border-opacity-20 h-[165px]" />
        <div className="flex sm:flex-row flex-col lg:gap-x-32 gap-x-20 gap-y-3 w-content sm:justify-center">
          {steps.map(({ name, description, iconSrc, iconTitle }, index) => (
            <Step
              key={`${name}-${index}`}
              number={index + 1}
              name={name}
              description={description}
              iconSrc={iconSrc}
              iconTitle={iconTitle}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default HowItWorksPage;
