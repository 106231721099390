/* eslint-disable no-undef */
import './App.css';
import HeroPage from './pages/Hero';
import OpportunitiesPage from './pages/Opportunities';
import HowItWorksPage from './pages/HowItWorks';
import DiscoverPage from './pages/Discover';
import PossibilitiesPage from './pages/Possibilities';
import RoadmapPage from './pages/Roadmap';
import TeamPage from './pages/Team';
import Footer from './pages/Footer';

function App() {
  return (
    <div>
      <HeroPage />
      <OpportunitiesPage />
      <HowItWorksPage />
      <DiscoverPage />
      <PossibilitiesPage />
      <RoadmapPage />
      <TeamPage />
      <Footer />
    </div>
  );
}

export default App;
